import { FeedItem } from '@commonstock/common/src/api/feed'
import { isLoggedIn } from '@commonstock/common/src/auth'
import React, { MouseEvent, useCallback, useMemo } from 'react'
import { CardIcon, repostIconClass } from 'src/components/styles'
import { IconButton } from 'src/composing/CSButton'
import { TextCaptionEmphasis } from 'src/composing/CSText'
import { SpacerH } from 'src/composing/Spacing'
import { Env } from 'src/config'
import { destructContentFeedItem, getFeedItemTypeBoolean } from 'src/scopes/content/utils'
import EditPost from 'src/scopes/post/EditPost'
import { useEphemeralModal } from 'src/scopes/modal/Modal'
import { Routes } from 'src/scopes/nav/constants'
import { JoinModalPublic } from 'src/scopes/public/JoinModalPublic'
import useLoggedOutModal from 'src/scopes/public/useLoggedOutModal'
import { flexCentered } from 'src/theme/AtomicClasses'
import { useTheme } from 'src/theme/ThemeContext'
import Tooltip from '../../components/Tooltip'
import { Repost } from '../../theme/NewIcons'

type Props = {
  feedItem: FeedItem
  disabled?: boolean
  prependItem?: (feedItem: FeedItem) => void
}
function RepostButton({ feedItem, disabled, prependItem }: Props) {
  const { post, trade } = getFeedItemTypeBoolean(feedItem)
  const { content, user } = destructContentFeedItem(feedItem)
  const modal = useEphemeralModal()
  const loggedOutModal = useLoggedOutModal(`Share`)

  const isDev = process.env.CS_ENV === Env.Dev
  const isStaging = process.env.CS_ENV === Env.Staging
  const origin = isStaging
    ? `https://staging.commonstock.dev`
    : isDev
    ? `https://commonstock.dev`
    : window.location.origin

  const tip = useMemo(() => {
    if (disabled) return 'Repost disabled'
    if (trade) return 'Repost trade'
    if (post) return 'Repost post'
    return ''
  }, [disabled, trade, post])

  const onClick = useCallback(
    async (e: MouseEvent) => {
      if (!user || !content) return
      const uuid = content.uuid

      e.preventDefault()
      e.stopPropagation()
      if (!isLoggedIn()) {
        loggedOutModal('Join', <JoinModalPublic />)
        return
      }
      const repostedLink = trade ? Routes.tradeDetail(uuid) : post ? Routes.postDetail(uuid) : ''

      modal(
        <EditPost
          prependItem={prependItem}
          isModal
          initialText={`\n${origin}${repostedLink} ${user.username ? `by @${user.username} ` : ''}`}
        />
      )
    },
    [content, loggedOutModal, post, modal, origin, prependItem, trade, user]
  )

  const { isSmallMobile } = useTheme()
  return (
    <CardIcon className={repostIconClass}>
      <Tooltip tip={tip}>
        <IconButton
          className={flexCentered}
          onClick={disabled ? undefined : onClick}
          disabled={disabled}
          aria-label={'Repost content'}
        >
          <Repost size={1.25} />
          <SpacerH rem={0.5} />
          {!isSmallMobile && <TextCaptionEmphasis>Repost</TextCaptionEmphasis>}
        </IconButton>
      </Tooltip>
    </CardIcon>
  )
}

export default RepostButton
